<template>
    <div class="forget-password">
        <v-container pa-6>
            <password-rule />
            <div class="input-item">
                <span>手机号</span>
                <input 
                    type="tel"
                    v-model="phone"
                    maxlength="11"
                />
            </div>
            <div class="input-item validate-code">
                <span>验证码</span>
                <input 
                    type="tel"
                    v-model="code"
                    maxlength="6"
                />
                <v-btn
                    class="button-send"
                    rounded
                    depressed
                    color="primary"
                    :disabled="getValidateCode || !phone"
                    @click="getCode"
                >
                    <span v-if="!getValidateCode">获取验证码</span>
                    <span v-if="getValidateCode">{{counter}}s 后重发</span>
                </v-btn>
            </div>
            <div class="input-item">
                <span>新密码</span>
                <input 
                    type="password"
                    v-model="password"
                    maxlength="20"
                />
            </div>
            <div class="input-item">
                <span>确认密码</span>
                <input 
                    type="password"
                    v-model="passwordConfirm"
                    maxlength="20"
                />
            </div>
        </v-container>
        <v-btn
            class="button-submit"
            rounded
            depressed
            color="primary"
            :disabled="!phone || !code || !password || !passwordConfirm"
            @click="submit"
        >
            提交
        </v-btn>
    </div>
</template>

<script>
import md5 from 'js-md5';
import PasswordRule from '@/components/password-rule';
import validateCodeMixins from '@/mixins/validateCode.mixins';
import {resetPassword, getPasswordMsg} from '@/service/service';

const phoneReg = /^1\d{10}$/;
const passReg = /^[A-Za-z]{1}(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,19}$/;
const codeReg = /^[0-9]{4,6}$/;
export default {
    name: 'ForgetPassword',

    components: {
        PasswordRule
    },

    mixins: [validateCodeMixins],

    data() {
        return {
            phone: '',
            code: '',
            password: '',
            passwordConfirm: '',
            hasToken: false,
        }
    },

    methods: {
        getCodeRequest(phone) {
            return getPasswordMsg({
                phone: phone,
                userName: phone,
            })
        },
        submit() {
            const phone = this.phone.replace(/\s/g, '')
            if (!phoneReg.test(phone)) {
                this.$toast.error('请输入正确的手机号码');
                return;
            }
            if (!codeReg.test(this.code)) {
                this.$toast.error('验证码错误');
                return;
            }
            if (!passReg.test(this.password)) {
                this.$toast.error('密码格式错误');
                return;
            }
            if (this.password !== this.passwordConfirm) {
                this.$toast.error('两次密码不一致');
                return;
            }
            this.$loading.show()
            resetPassword({
                userName: phone,
                newPassword: md5(this.password).toLocaleUpperCase(),
                code: this.code,
                key: this.codeKey,
            }).then(() => {
                this.$loading.hide()
                this.$toast.success('密码设置成功');
                setTimeout(() => {
                    this.$router.push('/login')
                });
            }).catch(err => {
                this.$loading.hide()
                this.$toast.error(err.msg || '请求失败')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.forget-password {
    height: 100%;
    overflow-y: auto;
}
.validate-code {
    padding-right: 0;
    .button-send {
        height: calc(100% + 2px) !important;
        margin-right: -1px;
        margin-top: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
</style>